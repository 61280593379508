// This file contains data/code for filtering our meters. I expect there to be some customization
// between projects. But, also a lot of reuse.
// TODO Create reusable choices and default value functions
import { appData } from './meter-data';
import appLogger from './logger';

const mlogger = appLogger.getLogger('filter-props');
// mlogger.logLevel = mlogger.loggerLevels.debug;

const FILTER_TYPES = {
    SELECT_MULTI: 'Select Multi',
    BOOL: 'Bool',
};

const meterSizeTextMap = {
    // The goal of this map is to have a reusable mapping of values to a standard text string
    0: '0"',
    0.58: '5/8"',
    0.75: '3/4"',
    1: '1"',
    1.25: '1.25"',
    1.5: '1.5"',
    2: '2"',
    3: '3"',
    4: '4"',
    5: '5"',
    6: '6"',
    7: '7"',
    8: '8"',
};

class UniqueValuesChoices {
    constructor(key) {
        this.key = key;
        this.cachedChoices = undefined;
        // If we don't bind choices, this will end up being the filter prop object and key
        // won't be found
        this.choices = this.choices.bind(this);
    }

    choices(data) {
        const flogger = mlogger.getLogger('unique-values-choices');
        if (this.cachedChoices === undefined) {
            flogger.debug(`Choices for ${this.label} filter have not been cached. Calculating choices ...`);
            if (data === undefined) {
                flogger.error('Argument passed to choices() is undefined. We expected a list of our data. Returning empty array.');
                return [];
            }
            const uniqueChoices = new Set();
            flogger.debug('Looking for unique values for our choices');
            data.forEach((item) => {
                if (Array.isArray(item[this.key])) {
                    item[this.key].forEach((subitem) => uniqueChoices.add(subitem));
                } else if (item[this.key] === undefined) {
                    uniqueChoices.add('Not Specified');
                } else {
                    uniqueChoices.add(item[this.key]);
                }
            });
            this.cachedChoices = Array.from(uniqueChoices).sort();
        }
        return this.cachedChoices;
    }
}

function boolChoices() {
    return [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
    ];
}

function defaultValueAll(choices) {
    const flogger = mlogger.getLogger('default-value-all');
    flogger.debug(`Calculating default value from ${choices}`);
    if (choices === undefined) {
        flogger.error('We expected choices to be given. Without it, we can\'t calculate our default value.');
        return undefined;
    }
    return choices.map((choice) => choice.value || choice);
}

const props = [
    {
        label: 'Routes',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: new UniqueValuesChoices('Route Name').choices,
        defaultValue: function defaultValue(choices) {
            const flogger = mlogger.getLogger('routes-default');
            if (choices === undefined) {
                flogger.error('We expected choices to be given. Without it, we can\'t calculate our default value.');
                return undefined;
            }
            return choices.filter((choice) => choice !== 'Test Meters');
        },
        filter: function filter(meter, selectedValues) {
            return !selectedValues.includes(meter['Route Name']);
        },
    },
    {
        label: 'Original Meter Size',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: function choices(data) {
            const flogger = mlogger.getLogger('orig-meter-size-choices');
            if (this.cachedChoices === undefined) {
                flogger.debug(`Choices for ${this.label} filter have not been cached. Calculating choices ...`);
                if (data === undefined) {
                    flogger.error('Argument passed to choices() is undefined. We expected a list of our data. Returning empty array.');
                    return [];
                }
                const uniqueSizes = new Set();
                flogger.debug('Looking for unique sizes for our choices');
                data.forEach((meter) => uniqueSizes.add(meter['Meter Size']));
                const sizeChoices = Array.from(uniqueSizes)
                    .filter((size) => size !== undefined).sort().map(
                        (size) => ({ text: meterSizeTextMap[size] || size, value: size }),
                    );
                sizeChoices.push({ text: 'Not Specified', value: 'Not Specified' });
                this.cachedChoices = sizeChoices;
            }
            return this.cachedChoices;
        },
        defaultValue: defaultValueAll,
        filter: function filter(meter, selectedValues) {
            const size = meter['Meter Size'] || 'Not Specified';
            return !selectedValues.includes(size);
        },
    },
    {
        label: 'New Meter Size',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: new UniqueValuesChoices('New Meter Size').choices,
        defaultValue: defaultValueAll,
        filter: function filter(meter, selectedValues) {
            const size = meter['New Meter Size'] || 'Not Specified';
            return !selectedValues.includes(size);
        },
    },
    {
        label: 'Town Attention Needed',
        type: FILTER_TYPES.BOOL,
        choices: boolChoices,
        defaultValue: function defaultValue() {
            return [true, false];
        },
        filter: function filter(meter, selectedValues) {
            const attentionNeeded = meter['Town Attention Needed'] || false;
            return !selectedValues.includes(attentionNeeded);
        },
    },
    {
        label: 'Need to Schedule Water Outage',
        type: FILTER_TYPES.BOOL,
        choices: boolChoices,
        defaultValue: function defaultValue() {
            return [true, false];
        },
        filter: function filter(meter, selectedValues) {
            const outageNeeded = meter['Need to Schedule Water Outage'] || false;
            return !selectedValues.includes(outageNeeded);
        },
    },
    {
        label: 'Parts Needed',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: new UniqueValuesChoices('Parts Needed').choices,
        defaultValue: defaultValueAll,
        filter: function filter(meter, selectedValues) {
            let partsNeeded = meter['Parts Needed'];
            if (!Array.isArray(partsNeeded) || partsNeeded.length === 0) {
                partsNeeded = ['Not Specified'];
            }
            for (let i = 0; i < partsNeeded.length; i++) {
                if (selectedValues.includes(partsNeeded[i])) {
                    return false;
                }
            }
            return true;
        },
    },
    {
        label: 'Parts Used',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: new UniqueValuesChoices('Parts Used').choices,
        defaultValue: defaultValueAll,
        filter: function filter(meter, selectedValues) {
            let partsUsed = meter['Parts Used'];
            if (!Array.isArray(partsUsed) || partsUsed.length === 0) {
                partsUsed = ['Not Specified'];
            }
            for (let i = 0; i < partsUsed.length; i++) {
                if (selectedValues.includes(partsUsed[i])) {
                    return false;
                }
            }
            return true;
        },
    },
    {
        label: 'Fire Service Meter',
        type: FILTER_TYPES.BOOL,
        choices: boolChoices,
        defaultValue: function defaultValue() {
            return [true, false];
        },
        filter: function filter(meter, selectedValues) {
            const fireServiceMeter = meter['Fire Service Meter'] || false;
            return !selectedValues.includes(fireServiceMeter);
        },
    },
    {
        label: 'Completed',
        type: FILTER_TYPES.BOOL,
        choices: boolChoices,
        defaultValue: function defaultValue() {
            return [true, false];
        },
        filter: function filter(meter, selectedValues) {
            const completed = meter.Completed || false;
            return !selectedValues.includes(completed);
        },
    },
    {
        label: 'Unsaved Changes',
        type: FILTER_TYPES.BOOL,
        choices: boolChoices,
        defaultValue: function defaultValue() {
            return [true, false];
        },
        filter: function filter(meter, selectedValues) {
            return !selectedValues.includes(
                appData.modifiedMeters[meter.ID] !== undefined,
            );
        },
    },
    // {
    //     label: 'In Export',
    //     type: FILTER_TYPES.SELECT_MULTI,
    //     choices: function choices() {
    //         // Gather all the export values. Alternatively, we could call /api/exports to
    //         // get a list.
    //         if (!this.cachedExportIds) {
    //             const exportIds = new Set();
    //             for (let i = 0; i < appData.meters.length; i++) {
    //                 const exports = appData.meters[i].Exports;
    //                 if (exports === undefined || !Array.isArray(exports)
    //                     || exports.length === 0) {
    //                     continue;
    //                 }
    //                 for (let j = 0; j < exports.length; j++) {
    //                     exportIds.add(exports[j]);
    //                 }
    //             }
    //             this.cachedExportIds = Array.from(exportIds);
    //             this.cachedExportIds.push('Not Exported');
    //         }
    //         return this.cachedExportIds;
    //     },
    //     defaultValue: function defaultValue(choices) {
    //         return [...choices];
    //     },
    //     filter: function filter(meter, selectedValues) {
    //         const exports = meter.Exports;
    //         if (exports === undefined || !Array.isArray(exports) || exports.length === 0) {
    //             return !selectedValues.includes('Not Exported');
    //         }
    //         for (let i = 0; i < exports.length; i++) {
    //             if (selectedValues.includes(exports[i])) {
    //                 return false;
    //             }
    //         }
    //         return true;
    //     },
    // },
];

export { FILTER_TYPES, props };
